<template lang="pug">
  div
    v-alert(
      type="error"
      outlined
      v-for="(item, index) in errors"
      :key="index"
      dismissible
    )
      span {{ item.first() }}
    v-row
      v-col(cols="3")
        create-order-status(
          ref="create-order-status"
        )
      v-col(cols="9")
        table-order-status(
          ref="table-order-status"
        )
</template>
<script>
export default {
  name: 'OrderStatuses',
  components: {
    createOrderStatus: () => import('./Create'),
    tableOrderStatus: () => import('./Table'),
  },
}
</script>